import React from 'react';

import HoverMenu from '../elements/hoverMenu';



class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (<div className="mb-3 px-1">
            <div className="d-flex flex-wrap" style={{ gap: "6px" }}>
                {this.props.config.startMenu.map((item, i) => {
                    return <div className="indexMenu flex-fill" key={i}>
                        <HoverMenu {...item} />
                    </div>
                })}
            </div>
        </div>);
    }
}

export default Menu;